import { useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import DialogHeader from '@/components/dialogs/dialog-header';
import OverlaySpinner from '@/components/loading/overlay-spinner';
import { lineCreate } from '@/graphql/mutations';
import { sensorGroups } from '@/graphql/queries';
import * as Types from '@/types';
import LineListTopology from '@/views/dialogs/line-settings/setup-line/line-list-topology';
import SelectSensor from '@/views/dialogs/line-settings/setup-line/select-sensor';
import SelectSensorGroup, { SensorGroup } from '@/views/dialogs/line-settings/setup-line/select-sensor-group';
import SpecifyLineInfo from '@/views/dialogs/line-settings/setup-line/specify-line-info';

const useStyles = makeStyles()((theme: Theme) => ({
  step: {
    maxHeight: '80px',
  },
  stepper: {
    maxHeight: '90px',
    paddingTop: '0px',
    paddingBottom: '10px',
  },
  stepContent: {
    flexGrow: 1,
  },
  backButton: {
    marginRight: theme.spacing(),
  },
  dialogSize: {
    [theme.breakpoints.down('md')]: {
      minWidth: '100% !important',
      minHeight: '100%',
    },
  },
  dialogBackgroundColor: {
    backgroundColor: '#fafafa',
    outlineStyle: 'none',
  },
}));

type LineInfo = {
  name: string;
  description: string;
};

interface Properties {
  handleOpenClose: (open: boolean) => void;
  open: boolean;
}

export const CreateLineDialog: FC<Properties> = ({ handleOpenClose, open }) => {
  const { classes } = useStyles();
  const [t] = useTranslation();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [sensorGroup, setSensorGroup] = useState<SensorGroup | null>(null);
  const [bottleneckSensor, setBottleneckSensor] = useState<
    Schema.SensorGroupsQuery['company']['groups'][0]['sensors'][0] | null | undefined
  >(null);
  const [nodes, setNodes] = useState<Types.GraphNode[]>([]);
  const [links, setLinks] = useState<Types.GraphLink[]>([]);
  const [lineInfo, setLineInfo] = useState<LineInfo>({ name: '', description: '' });
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  const steps = [
    t(['line:selectSensorGroup'], { defaultValue: 'Select a sensor group' }),
    t(['line:createLine'], { defaultValue: 'Create line' }),
    t(['line:describeLineTopology'], { defaultValue: 'Line composition (optional)' }),
    t(['line:lineSetupFinished'], { defaultValue: 'Finished line setup' }),
  ];

  const [createLine, { loading }] = useMutation<Schema.LineCreateMutation, Schema.LineCreateMutationVariables>(
    lineCreate,
  );

  useEffect(() => {
    if (activeStep === 0) {
      resetState();
    }
  }, [activeStep]);

  useEffect(() => {
    let disabled = true;
    switch (activeStep) {
      case 0:
        disabled = !sensorGroup;
        break;
      case 1:
        disabled = !(bottleneckSensor && lineInfo.name);
        break;
      case 2:
        disabled = false;
        break;
      case 3:
        disabled = true;
        break;
      default:
        console.error('Unrecognized step! Value: ' + activeStep + ' Type: ' + typeof activeStep);
        break;
    }
    setNextButtonDisabled(disabled);
  }, [activeStep, sensorGroup, bottleneckSensor, lineInfo]);

  useEffect(() => {
    if (bottleneckSensor && !nodes.find((node) => node.peripheralId === bottleneckSensor.peripheralId)) {
      const newGraphNode: Types.GraphNode = {
        sensorName: bottleneckSensor.name,
        sensorDescription: bottleneckSensor.description,
        peripheralId: bottleneckSensor.peripheralId,
        nodeType: Schema.NodeType.Main,
        sensorType: bottleneckSensor.config.type,
        registerStops: true,
        oee: true,
      };
      setNodes([...nodes, newGraphNode]);
    }
  }, [bottleneckSensor]);

  const resetState = () => {
    setSensorGroup(null);
    setBottleneckSensor(null);
    setNodes([]);
    setLinks([]);
    setLineInfo({ name: '', description: '' });
    setNextButtonDisabled(true);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const handleClose = () => {
    setActiveStep(0);
    handleOpenClose(false);
  };

  const handleNext = () => async () => {
    // Now with the last step, that informs you to wait 5 min. and refresh, minus 2 is needed
    if (activeStep === steps.length - 2) {
      await submitFinishedLine();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const submitFinishedLine = async () => {
    if (!sensorGroup) {
      return;
    }

    await createLine({
      refetchQueries: [{ query: sensorGroups }],
      variables: {
        groupId: sensorGroup.id,
        name: lineInfo.name,
        description: lineInfo?.description || '',
        nodes: nodes.map((node) => ({
          peripheralId: node.peripheralId,
          type: node.nodeType,
        })),
        edges: links.map((link) => ({
          from: link.source,
          to: link.target,
        })),
      },
    });
    setActiveStep(activeStep + 1);
  };

  const renderActionButtons = () => {
    const backButton = (
      <Button onClick={handleBack} className={classes.backButton}>
        {t(['shared:back'], { defaultValue: 'Back' })}
      </Button>
    );
    const nextButton = (
      <Button variant="contained" disabled={nextButtonDisabled} color="primary" onClick={handleNext()}>
        {t(['shared:next'], { defaultValue: 'Next' })}
      </Button>
    );
    const finishButton = (
      <Button variant="contained" color="primary" onClick={handleNext()}>
        {t(['shared:finish'], { defaultValue: 'Finish' })}
      </Button>
    );

    switch (activeStep) {
      case 0:
        return nextButton;
      case 1:
        return [backButton, nextButton];
      case 2:
        return [backButton, finishButton];
      case 3:
        return null;
      default:
        console.error('Unknown step index!');
    }
  };

  const sensorTypeToNodeType = (sensorType: Schema.SensorType | null | undefined): Schema.NodeType => {
    if (!sensorType) {
      return Schema.NodeType.Counter;
    }
    switch (sensorType) {
      case Schema.SensorType.COUNTER:
      case Schema.SensorType.COUNTER_ACCUMULATE:
      case Schema.SensorType.COUNTER_SPEED:
      case Schema.SensorType.DISCRETE:
      case Schema.SensorType.MANUAL_PROCESS:
      case Schema.SensorType.MEASUREMENT:
        return Schema.NodeType.Counter;
      case Schema.SensorType.EVENT:
        return Schema.NodeType.Event;
      default:
        console.warn('Unrecognized sensor type!');
        return Schema.NodeType.Counter;
    }
  };

  const onSelectSensorWithLineSettings = (
    sensor: NonNullable<Schema.SensorGroupsQuery['company']['groups'][0]['sensors'][0]>,
  ) => {
    const isCamera = sensor.peripheralType === Schema.PeripheralType.CAMERA;

    const existingSensorNode = nodes.find((node) => node.peripheralId === sensor.peripheralId);
    const id = existingSensorNode?.peripheralId;
    if (existingSensorNode && existingSensorNode.nodeType !== Schema.NodeType.Main) {
      const updatedNodes = nodes.filter((node) => node.peripheralId !== id);
      const updatedLinks = links.filter((link) => link.source !== id && link.target !== id);
      setNodes(updatedNodes);
      setLinks(updatedLinks);
    } else if (!nodes.some((node) => node.peripheralId === sensor.peripheralId)) {
      const newGraphNode: Types.GraphNode = {
        sensorName: sensor.name,
        sensorDescription: sensor.description,
        peripheralId: sensor.peripheralId,
        sensorType: sensor.config.type,
        nodeType: isCamera ? Schema.NodeType.Camera : sensorTypeToNodeType(sensor.config.type),
        registerStops: false,
        oee: false,
      };
      setNodes([...nodes, newGraphNode]);
    }
  };

  const onAddSensorNode = (peripheralId: string, isBeforeMain: boolean) => {
    const updatedNodes = nodes.map((node) => {
      if (node.peripheralId !== peripheralId) {
        return node;
      }
      return {
        ...node,
        nodeType: Schema.NodeType.Scrap,
      };
    });
    setNodes(updatedNodes);

    let updatedLinks = [...links];
    const source = isBeforeMain ? peripheralId : bottleneckSensor!.peripheralId;
    const target = isBeforeMain ? bottleneckSensor!.peripheralId : peripheralId;
    updatedLinks.push({ source, target });
    setLinks(updatedLinks);
  };

  const onRemoveSensorNode = (peripheralId: string) => {
    const updatedNodes = nodes.map((node) => {
      if (node.peripheralId !== peripheralId) {
        return node;
      }
      return {
        ...node,
        nodeType: sensorTypeToNodeType(node.sensorType),
      };
    });
    setNodes(updatedNodes);
    let updatedLinks = links.filter((link) => link.source !== peripheralId && link.target !== peripheralId);
    setLinks(updatedLinks);
  };

  const onSelectBottleneckSensor = (sensor: Schema.SensorGroupsQuery['company']['groups'][0]['sensors'][0]) => {
    const matchingNode = nodes.find((node) => node.peripheralId === bottleneckSensor?.peripheralId);

    if (matchingNode) {
      const filteredNodes = [...nodes.filter((node) => node.peripheralId !== matchingNode.peripheralId)];
      setNodes(filteredNodes);
    }

    setBottleneckSensor(sensor);
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <SelectSensorGroup selectedGroup={sensorGroup} onSelectSensorGroup={setSensorGroup} />;
      case 1:
        return (
          <Grid container spacing={2} alignItems="stretch" justifyContent="flex-start">
            <Grid item xs={12}>
              <SpecifyLineInfo lineInfo={lineInfo} onChangeLineInfo={setLineInfo} />
            </Grid>
            <Grid item xs={12}>
              <SelectSensor
                title={
                  t(['line:selectBottleneckCounter'], {
                    defaultValue: 'Select the bottleneck sensor of the line',
                  }) + '*'
                }
                disabledSensorId={undefined}
                sensors={(sensorGroup?.availableSensors || []).filter(Types.isTruthy)}
                selectedSensorIds={bottleneckSensor ? [bottleneckSensor.peripheralId] : []}
                onSelectSensor={onSelectBottleneckSensor}
                selectDefault
              />
            </Grid>
          </Grid>
        );

      case 2:
        return (
          <>
            <p>
              {t(['line:addRemainingSensors'], {
                defaultValue: 'To get complete overview of your line, please add any remaining sensors',
              })}
            </p>{' '}
            <SelectSensor
              disabledSensorId={bottleneckSensor?.peripheralId}
              title={
                t(['line:selectOtherSensors'], { defaultValue: 'Select all additional sensors on line' }) +
                ' ' +
                lineInfo.name
              }
              selectedSensorIds={nodes.map((node) => node.peripheralId)}
              sensors={(sensorGroup?.availableSensors || []).filter(Types.isTruthy)}
              onSelectSensor={onSelectSensorWithLineSettings}
            />
            <Divider></Divider>
            <LineListTopology
              // Only non-bottleneck and counter sensors can be scrap
              availableNodes={nodes.filter(
                (node) =>
                  node.nodeType !== Schema.NodeType.Main &&
                  (node.sensorType === Schema.SensorType.COUNTER ||
                    node.sensorType === Schema.SensorType.COUNTER_ACCUMULATE),
              )}
              mainSensorNodeName={bottleneckSensor!.name}
              mainSensorNodePeripheralId={bottleneckSensor!.peripheralId}
              nodeLinks={links}
              onAddScrapSensor={onAddSensorNode}
              onRemoveScrapSensor={onRemoveSensorNode}
            />
          </>
        );
      case 3:
        return (
          <Grid container justifyContent="center">
            <Grid item sx={{ textAlign: 'center', maxWidth: '500px' }}>
              <Typography variant="body1">
                {t(['line:creationDelayExplanation'], {
                  defaultValue:
                    'It might take up to 5 minutes for the line to appear in the Lines Overview. Try refreshing the page after closing this dialog.',
                })}
              </Typography>
            </Grid>
          </Grid>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={handleClose}
      PaperProps={{
        className: `${classes.dialogBackgroundColor} ${classes.dialogSize}`,
        style: { display: 'flex', flexDirection: 'column' },
      }}
    >
      <DialogHeader title={steps[activeStep]} onClose={handleClose} />
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={`${classes.dialogBackgroundColor} ${classes.stepper}`}
      >
        {steps.map((label, i) => {
          if (i === steps.length - 1) {
            // We deliberately don't want to show the finish page as a step.
            return null;
          }
          return (
            <Step key={label} className={classes.step}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <DialogContent className={`${classes.dialogBackgroundColor} ${classes.stepContent}`}>
        {getStepContent()}
      </DialogContent>
      <DialogActions className={classes.dialogBackgroundColor}>{renderActionButtons()}</DialogActions>
      {loading ? <OverlaySpinner /> : null}
    </Dialog>
  );
};
