import { intlFormat, nextMonday, setDay } from 'date-fns';
import * as Schema from 'generated/graphql/schema';

import * as Types from '@/types';

// TODO: Drop the use of this function, it's too simple to keep :p Excel does
// the formatting for us.
export const excelReadableDateFormat = (date: string) => {
  if (!date) {
    return '';
  }

  return new Date(date);
};

export const localDayWithTimezoneOffset = (date: Date) => {
  const localDay = new Date(date.getTime() - date.getTimezoneOffset() * (1).minutes);
  const [calendarDay] = localDay.toISOString().split('T');

  return calendarDay;
};

export const guardedCallbackNumber = (callback: (value: number) => any) => {
  return <T extends { target: { value: any } }>({ target: { value } }: T) => {
    const parsedValue = Number(value);

    if (Number.isNaN(parsedValue)) {
      return;
    }

    return callback(parsedValue);
  };
};

export const guardedCallbackString = <T = any>(callback: (value: T) => any) => {
  return <EVENT_TYPE extends { target: { value: any } }>({ target: { value } }: EVENT_TYPE) => {
    if (typeof value !== 'string') {
      return;
    }

    return callback(value as unknown as T);
  };
};

export const guardedCallback = guardedCallbackString;

export const mod = (n: number, m: number) => {
  return ((n % m) + m) % m;
};

//Missing type export in intlFormat
type DateTimeFormatOptions = NonNullable<Parameters<typeof intlFormat>[1]>;

/**
 * Time Formatting 7/3/2021 13:00 or 3/7/2021 1:00 PM
 * @param time
 */
export const timeFormatyyyyMMdhmm = (time: Date, locale?: string) => {
  const genericTimeFormat: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return intlFormat(time, genericTimeFormat, { locale });
};

export function millisAsmmss(ms: number): string {
  const minutes = Math.floor(ms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);
  return `${minutes.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}:${sec.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}`;
}

/**
 * Time Formatting 7/3/2021 13:00:00 or 3/7/2021 1:00:00 PM
 * @param time
 */
export const timeFormatyyyyMMdhmmss = (time: Date, locale?: string) => {
  const genericTimeFormat: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return intlFormat(time, genericTimeFormat, { locale });
};

/**
 * Time Formatting 7. marts 13:00 or March 7, 1:00 PM
 * @param time
 */
export const timeFormatdMMMMhmm = (time: Date, locale?: string) => {
  const genericTimeFormat: DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return intlFormat(time, genericTimeFormat, { locale });
};
/**
 * Time Formatting 13:00 or 1:00 PM
 * @param time
 */
export const timeFormathhmm = (time: Date, locale?: string) => {
  const genericTimeFormat: DateTimeFormatOptions = {
    // month: 'long',
    // day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return intlFormat(time, genericTimeFormat, { locale });
};

/**
 * Time Formatting 7. marts 2021 13:00 or March 7, 2021, 1:00 PM
 * @param time
 */
export const timeFormatdMMMMyyyyhhmm = (time: Date, locale?: string) => {
  const genericTimeFormat: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return intlFormat(time, genericTimeFormat, { locale });
};

/**
 * Time Formatting 7/3/2021 or 3/7/2021
 * @param time
 */
export const timeFormatyyyyMMdd = (time: Date, locale?: string) => {
  if (time instanceof Date === false || isNaN(time.getTime())) {
    console.error('!Error: Parameter time was not an instane of date!');
    return 'Invalid Date!';
  }
  const genericTimeFormat: DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  return intlFormat(time, genericTimeFormat, { locale });
};

/**
 * Time Formatting 7/3/2021 13:00:00 or 3/7/2021 1:00:00 PM
 * @param time
 */
export const timeFormatyyyyMMMMdhmmss = (time: Date, locale?: string) => {
  const genericTimeFormat: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return intlFormat(time, genericTimeFormat, { locale });
};

/**
 * Time Formatting:
 * Wed, 7. Mar, 2021, 13:00:00 Central European Standard time
 * or
 * Wed, Mar 7, 2021, 1:00:00 PM Central European Standard time
 * @param time
 */
export const timeFormatyyyyMMMddhhmmsstimezone = (time: Date, locale?: string) => {
  const genericTimeFormat: DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    weekday: 'short',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'long',
  };
  return intlFormat(time, genericTimeFormat as any, { locale });
};

let isBrowserLocale24hCache: Record<string, boolean> = {};

/**
 * return true if locale in browser is 24h format
 */
export const isBrowserLocale24h = (language: string) => {
  if (isBrowserLocale24hCache.hasOwnProperty(language)) {
    return isBrowserLocale24hCache[language];
  } else {
    let res = !new Intl.DateTimeFormat(language || navigator.language, { hour: 'numeric' }).format(0).match(/AM|PM/);
    isBrowserLocale24hCache[language] = res;
    return res;
  }
};
/**
 * Time Formatting Monday, 15. Mar, 2021 - 13:00:00.000 or Monday, Mar 15, 2021 - 01:00:00.000 PM
 */
export const xDateFormatBasedOnBrowserLocale = (language: string) => {
  if (isBrowserLocale24h(language)) {
    return '%A, %b %e, %Y - %H:%M:%S.%L';
  }
  return '%A, %e. %b, %Y - %I:%M:%S.%L%p';
};

/**
 * Time Formatting adds AM/PM formatting if browser locale is AM/PM
 */
export const DateTimeLabelFormatBasedOnBrowserLocale = (language: string) => {
  var formats: any = {};
  if (isBrowserLocale24h(language)) {
    formats = {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e. %b',
      week: '%e. %b',
      month: '%e. %b',
      year: '%b',
    };
  } else {
    formats = {
      millisecond: '%I:%M:%S.%L%p',
      second: '%I:%M:%S%p',
      minute: '%I:%M%p',
      hour: '%I:%M%p',
      day: '%b %e',
      week: '%b %e',
      month: '%b %e',
      year: '%b',
    };
  }
  return formats;
};

/**
 * Note: Format this way, instead of using date-fns, because date-fns format is timezone aware,
 * resulting in format(0, 'HH:mm:ss') = '01:00:00' for GMT+1.
 */
export const toHHMMSS = (v: number) => {
  const secNum = Math.abs(Math.floor(v / 1000));
  let hours: number | string = Math.floor(secNum / 3600);
  let minutes: number | string = Math.floor((secNum - hours * 3600) / 60);
  let seconds: number | string = secNum - hours * 3600 - minutes * 60;

  hours = hours === 0 || isNaN(hours) ? '00' : (hours < 10 ? '0' : '') + `${hours}`;
  minutes = minutes === 0 || isNaN(minutes) ? '00' : (minutes < 10 ? '0' : '') + `${minutes}`;
  seconds = seconds === 0 || isNaN(seconds) ? '00' : (seconds < 10 ? '0' : '') + `${seconds}`;

  const fs = hours + ':' + minutes + ':' + seconds;

  return v < 0 ? `-${fs}` : `${fs}`;
};

/**
 * if i=14 it returns 14:00 or 2:00 PM depending on browser locale
 * @param i
 */
export const timeFormatVariable = (i: number, language: string) => {
  if (isBrowserLocale24h(language)) {
    return i + ':00';
  } else {
    var timeString = '';
    if (i % 12 !== 0) {
      timeString = (i % 12).toString();
    } else {
      timeString = (12).toString();
    }
    if (i > 11) {
      timeString = timeString + ':00 PM';
    } else {
      timeString = timeString + ':00 AM';
    }
    return timeString;
  }
};

/**
 * turns number of pcs per time into wanted time unit (min/hour/day)
 */
export const convertPcsPerTimeToWantedUnit = (
  noOfPcs: number,
  currentUnit: Schema.ChartTimeScale,
  wantedUnit: Schema.ChartTimeScale,
) => {
  let pcsPerMin = 0;

  //Convert pcs/currentUnit into pcs/min
  switch (currentUnit) {
    case Schema.ChartTimeScale.DAY:
      pcsPerMin = noOfPcs / 60 / 24;
      break;
    case Schema.ChartTimeScale.HOUR:
      pcsPerMin = noOfPcs / 60;
      break;
    case Schema.ChartTimeScale.MINUTE:
      pcsPerMin = noOfPcs;
      break;
    default:
      Types.assertUnreachable(currentUnit);
      break;
  }

  //Convert pcs/min into pcs/wantedUnit
  let pcsPerWantedUnit = 0;
  switch (wantedUnit) {
    case Schema.ChartTimeScale.DAY:
      pcsPerWantedUnit = pcsPerMin * 60 * 24;
      break;
    case Schema.ChartTimeScale.HOUR:
      pcsPerWantedUnit = pcsPerMin * 60;
      break;
    case Schema.ChartTimeScale.MINUTE:
      pcsPerWantedUnit = pcsPerMin;
      break;
    default:
      Types.assertUnreachable(wantedUnit);
      break;
  }

  return pcsPerWantedUnit;
};

export const isNewFeature = (releaseTime: string, age = (7).days) => {
  return Date.now() <= new Date(releaseTime).getTime() + age;
};

export function sleep(milliseconds: number): Promise<void> {
  return new Promise((r) => setTimeout(r, milliseconds));
}

export const evaluateWeekSpan = (date: Date) => {
  const dateCopy = new Date(date);

  dateCopy.setHours(0, 0, 0, 0);
  const monday = setDay(dateCopy, 1);
  const mondayNextWeek = nextMonday(monday);

  return [monday, mondayNextWeek];
};
