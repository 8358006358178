import { Add } from '@mui/icons-material';
import { Fab, Tooltip } from '@mui/material';
import { FabProps } from '@mui/material/Fab';
import { Children, ComponentType, HTMLAttributes, cloneElement, isValidElement } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  stickyBottom: {
    // Escape the grouped stacking context to avoid filter/opacity bleeding effects.
    zIndex: 1,
    bottom: theme.spacing(4),
    height: 68,
    marginBottom: theme.spacing(3),
    position: 'sticky',
    width: 1,
    left: '100%',
    '@media print': {
      display: 'none',
    },
  },
  bottomRight: {
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(3),
  },
}));

export interface Properties {
  title?: string;
  onClick?: FabProps['onClick'];
  disabled?: boolean;
  Icon?: ComponentType;
}

const StickyFab: React.FC<Properties> = ({ children, disabled = false, title = '', onClick, Icon = Add }) => {
  const { classes } = useStyles();

  const child = children && Children.only(children);

  const button = isValidElement<HTMLAttributes<HTMLElement>>(child) ? (
    cloneElement(child, { className: classes.bottomRight })
  ) : (
    <Fab color="primary" disabled={disabled} className={classes.bottomRight} onClick={onClick}>
      <Icon />
    </Fab>
  );

  return (
    <div className={classes.stickyBottom}>
      <Tooltip title={title} placement="top-start">
        {button}
      </Tooltip>
    </div>
  );
};

export default StickyFab;
